import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/docs/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "rate-limiting"
    }}>{`Rate limiting`}</h1>
    <p>{`The Customers API uses spike arrests and quotas to ensure rate limiting. Spikes are identified by client IP. Quotas are identified by authorization token.
Quotas are limited per minute and countdown starts from the first request sent. Exceeding limitations will result in `}<inlineCode parentName="p">{`429 TOO_MANY_REQUESTS`}</inlineCode>{`.
Clients needs to limit the amount of requests they are allowing through with the same authorization token to make sure that Denial-of-Service does not happen.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      